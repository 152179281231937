$(function () {
    'use strict';

    /**
     * Bootstrap Select
     */
    $('select').selectpicker();

    /**
     * Background image
     */
    $('*[data-background-image]').each(function () {
        $(this).css({
            'background-image': 'url(' + $(this).data('background-image') + ')'
        });
    });

    /**
     * Listing Detail Map
     */
    var listing_detail_map = $('#listing-detail-map');
    if (listing_detail_map.length) {

        listing_detail_map.google_map({
            center: {
                latitude: listing_detail_map.data('latitude'),
                longitude: listing_detail_map.data('longitude')
            },
            zoom: listing_detail_map.data('zoom'),
            transparentMarkerImage: listing_detail_map.data('transparent-marker-image'),
            transparentClusterImage: listing_detail_map.data('transparent-marker-image'),
            infowindow: {
                borderBottomSpacing: 0,
                height: 195,
                width: 165,
                offsetX: 30,
                offsetY: -120
            },
            markers: [
                {
                    latitude: listing_detail_map.data('latitude'),
                    longitude: listing_detail_map.data('longitude'),
                    marker_content: '<div class="marker"><div class="marker-inner"><i class="' + listing_detail_map.data('icon') + '"></div></div>'
                }
            ]
        });
    }

    /**
    * UA & GA4 in search page
     */
    const _push_custom_event_to_GA = function () {
        if (arguments.length < 1) {
            console.error('Please specify at least the first parameter for eventName.');
            return;
        } else if (arguments.length % 2 === 0) {
            console.error('The number of arguments must be odd. Arguments should be as followed: eventName, (param1, value1, param2, value2...). Keys/values are optional');
            return;
        }

        let pushContent = {
            'event': 'ga4Event',
            'eventName': arguments[0]
        }

        if (arguments.length > 1) {
            for (let i = 1; i < arguments.length; i += 2) {
                pushContent[arguments[i]] =  arguments[i + 1];
            }
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(pushContent);
    };
    var onlyOnce = true;
    if($('[data-id="regionSelect"]').attr('title') || $('[data-id="categorySelect"]').attr('title') && onlyOnce) {
        _push_custom_event_to_GA(
            'community_search_notice',
            'notice_type', ($('[data-id="categorySelect"]').attr('title') !== 'Sélectionnez le type d’avis' && $('[data-id="categorySelect"]').attr('title') !== 'Select notice type')?$('[data-id="categorySelect"]').attr('title'):'none',
            'notice_region', ($('[data-id="regionSelect"]').attr('title') !== 'Sélectionnez la ville ou la région' && $('[data-id="regionSelect"]').attr('title') !== 'Select city or region')?$('[data-id="regionSelect"]').attr('title'):'none'
        );
        onlyOnce = false;
    }

    /**
     * Listing Detail Street View
     */
    $('#listing-detail-location a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var target = $(this).attr('href');

        if (target == '#street-view-panel') {

            var street_view = $('#listing-detail-street-view');

            new google.maps.StreetViewPanorama(document.getElementById('listing-detail-street-view'), {
                position: {
                    lat: street_view.data('latitude'),
                    lng: street_view.data('longitude')
                },
                pov: {
                    heading: street_view.data('heading'),
                    pitch: street_view.data('pitch')
                },
                zoom: street_view.data('zoom'),
                linksControl: false,
                panControl: false,
                visible: true
            });
        }
    });

    /**
     * Textarea resizer
     */
    $("textarea").after('<div class="textarea-resize"></div>');

    /*
    * SVG Injector
    */
    // Elements to inject
    var mySVGsToInject = document.querySelectorAll('img.inject-me');
    // Do the injection
    SVGInjector(mySVGsToInject);


    // code for the subscription page
    $('.phone_number_mask').mask('(000) 000-0000');


    $("input:checkbox").focus(function () {
        $(this).parent('label').addClass("focus");

    }).blur(function () {
        $(this).parent('label').removeClass("focus");
    });

    $("input:radio").focus(function () {
        $(this).parent('label').addClass("focus");

    }).blur(function () {
        $(this).parent('label').removeClass("focus");
    });
});
